export default {
  colors: {
    purple: '#662D8C',
    lightPurple: '#B296C5',
    brightPurple: '#773DBD',
    violet: '#5E0440',
    red: '#E54360',
    orange: '#FA6666',
    lightOrange: '#FF6C0E',
    yellow: '#E0CC00',
    blue: '#2672CE',
    darkBlue: '#1226AA',
    pink: '#F090C3',
    grey: '#C4C4C4',
    green: '#00B189',
    darkGreen: '#006B68',
  },
}
