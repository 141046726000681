import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ClientInitializer, { Client } from 'shopify-buy/index.unoptimized.umd'

import Context from '../context/StoreContext'
import useCart from '../hooks/useCart'
import useProductDetail from '../hooks/useProductDetail'

const ShopifyClient: Client = ClientInitializer.buildClient({
  domain: `shop.eatyourgrummies.com`,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN || '',
})

const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          sku
          bundle
          slug
          childProducts
          title
          shortTitle
          color
          colorSecondary
          description
          cardCopy
          oneLiner
          ingredientFact
          gradient
          pdpGradient
          pdpTransform
          superfood
          images {
            bottles {
              single {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              multipack_2 {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              multipack_4 {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            cardImg {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            benefitsGummy {
              childImageSharp {
                fluid(maxWidth: 40) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            bullet {
              childImageSharp {
                fluid(maxWidth: 40) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            supplementFacts {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          pdpBlobs {
            blobCss
            img {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          research {
            label
            header
            bullets {
              title
              citation
              link
            }
          }
          keyBenefits {
            label
            detail
          }
          certifications {
            name
            overrideLabel
          }
        }
      }
    }
  }
`

const StoreContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [cartMenuOpen, setCartMenuOpen] = useState(false)

  const data = useStaticQuery(query)

  const { productMap, fetchShopifyProductDetail, lookupProducts } = useProductDetail(
    ShopifyClient,
    data
  )
  const {
    store,
    initializeCart,
    addVariantToCart,
    removeVariantFromCart,
    updateCartLineItem,
    swapVariant,
  } = useCart(ShopifyClient, productMap)

  const initializeApp = async () => {
    setLoading(true)
    await fetchShopifyProductDetail()
    await initializeCart()
    setLoading(false)
  }

  useEffect(() => {
    initializeApp()
  }, [])

  return (
    <Context.Provider
      value={{
        loading,
        store,
        products: productMap,
        lookupProducts,
        addVariantToCart,
        removeVariantFromCart,
        updateCartLineItem,
        swapVariant,
        cartMenuOpen,
        setCartMenuOpen,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default StoreContextProvider
