// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-giveaways-tsx": () => import("./../../../src/pages/a/giveaways.tsx" /* webpackChunkName: "component---src-pages-a-giveaways-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-giveaways-tsx": () => import("./../../../src/pages/giveaways.tsx" /* webpackChunkName: "component---src-pages-giveaways-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-real-tsx": () => import("./../../../src/pages/real.tsx" /* webpackChunkName: "component---src-pages-real-tsx" */),
  "component---src-pages-shipping-and-returns-tsx": () => import("./../../../src/pages/shipping-and-returns.tsx" /* webpackChunkName: "component---src-pages-shipping-and-returns-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-pdp-tsx": () => import("./../../../src/templates/pdp.tsx" /* webpackChunkName: "component---src-templates-pdp-tsx" */)
}

