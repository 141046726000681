import qs, { ParsedQs } from 'qs'

declare global {
  interface Window {
    [key: string]: any
  }
}

export const getGoogleClientId = (): Promise<string> => {
  return new Promise(resolve => {
    let loaded = false

    window.dataLayer = window.dataLayer || []
    window.gtag =
      window.gtag ||
      function gtag() {
        window.dataLayer.push(arguments)
      }
    window.gtag('js', new Date())

    window.gtag(
      (function() {
        // this function is called after GA library initializes
        loaded = true

        if (!window.ga || (window.ga && !window.ga.getAll)) return resolve('')

        const trackers = window.ga.getAll()
        const tracker = trackers[0]
        const clientId: string = tracker && tracker.get('clientId')

        return resolve(clientId)
      })()
    )

    setTimeout(function() {
      if (!loaded) resolve('')
    }, 1500)
  })
}

export const getQueryStringParameter = (key: string) => {
  const validWindow = typeof window !== 'undefined'

  let value: any = ''
  if (validWindow) {
    const queryParams: ParsedQs = qs.parse(window.location.search.substr(1))
    value = queryParams[key] || ''
  }

  return [key, value]
}

export const initializeGaEnhancedEcommerce = () => {
  if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
    return getGoogleClientId()
  }
}

export const trackAddToCartEvent = (
  name: string,
  quantity: number,
  variantId: string,
  unitPrice: number
) => {
  const validWindow = typeof window !== 'undefined'

  // Google Analytics
  if (validWindow && typeof window.gtag !== 'undefined') {
    window.gtag('event', 'add_to_cart', {
      items: [{ name, quantity }],
    })
  } else {
    console.log(`Sent GA "Add to Cart" event for ${name}`)
  }
  // Facebook Pixel
  if (validWindow && typeof window.fbq !== 'undefined') {
    window.fbq('track', 'AddToCart', {
      content_type: 'product',
      content_name: name,
      content_ids: [variantId],
      contents: [{ id: variantId, quantity }],
      currency: 'USD',
      value: quantity * unitPrice,
    })
  }

  // Pinterest
  if (validWindow && typeof window.pintrk !== 'undefined') {
    window.pintrk('track', 'addtocart', {
      currency: 'USD',
      product_name: name,
      product_price: unitPrice,
      product_quantity: quantity,
      product_variant_id: [variantId],
      value: quantity * unitPrice,
    })
  }
}

export const trackProductViewEvent = (
  name: string,
  slug: string,
  variantIds: string[],
  price: number
) => {
  const validWindow = typeof window !== 'undefined'

  // Google Analytics
  if (validWindow && typeof window.gtag !== 'undefined') {
    window.gtag('event', 'view_item', {
      items: variantIds.map(id => ({ name, id })),
    })
  } else {
    // For dev/local environments, then just console.log
    console.log(`Sent PDP PageView for ${name}`)
  }

  // Facebook Pixel
  if (validWindow && typeof window.fbq !== 'undefined') {
    window.fbq('track', 'ViewContent', {
      content_type: 'product',
      content_name: name,
      content_ids: variantIds,
      currency: 'USD',
      value: price,
    })
  }

  // Klaviyo Track Product View
  var _learnq = window._learnq || [] // eslint-disable-line no-var
  const item = {
    ProductName: name,
    ProductID: name,
    URL: `https://www.eatyourgrummies.com/${slug}`,
    Brand: 'Grummies',
  }

  _learnq.push(['track', 'Viewed Product', item])

  _learnq.push([
    'trackViewedItem',
    {
      Title: item.ProductName,
      ItemId: item.ProductID,
      Url: item.URL,
      Metadata: {
        Brand: item.Brand,
      },
    },
  ])

  // Pinterest
  if (validWindow && typeof window.pintrk !== 'undefined') {
    window.pintrk('track', 'pagevisit', {
      currency: 'USD',
      product_name: name,
      product_price: price,
      product_variant_id: variantIds,
    })
  }
}

//  Not currently being used anywhere
export const trackCustomGAClickEvent = (eventAction: string, name: string, category?: string) => {
  const validWindow = typeof window !== 'undefined'
  const eventCategory = category || 'engagement' // 'ecommerce" | "engagement"

  // Google Analytics
  if (validWindow && typeof window.gtag !== 'undefined') {
    const googleEventDetail = {
      event_category: eventCategory,
      event_label: name,
    }

    window.gtag('event', eventAction, googleEventDetail)
  } else {
    // For dev/local environments, then just console.log
    console.log(`Sent Click Event for ${name}`)
  }
}
