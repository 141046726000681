import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { Cart, CustomAttribute, Client as ClientResource } from 'shopify-buy'

import encodeVariantId from '../utils/encodeVariantId'
import { createCookie, getCookie, deleteCookie } from '../utils/cookies'
import {
  initializeGaEnhancedEcommerce,
  getQueryStringParameter,
  trackAddToCartEvent,
} from '../utils/event-tracking'

interface ProductMapInterface {
  byProduct: { [key: string]: any }
  byVariantId: { [key: string]: any }
}

interface StoreInterface {
  client?: ClientResource
  cart?: Cart
  updating: boolean
}

const useCart = (ShopifyClient: ClientResource, productMap: ProductMapInterface) => {
  const SHOPIFY_CART_ID = 'shopify_cart_id'
  const COOKIE_EXPIRATION_DAYS = 7

  const initialState = { updating: true }
  const [store, setStore] = useState(initialState)
  const [cartId, setCartId] = useState('')

  const initializeCart = async () => {
    try {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined'
      const existingCartID = getCookie(SHOPIFY_CART_ID) || ''

      const setCartInState = (cart: Cart) => {
        if (isBrowser && cart && cart.id) {
          createCookie(SHOPIFY_CART_ID, cart.id, COOKIE_EXPIRATION_DAYS)
          setStore((prevState: StoreInterface) => ({ ...prevState, cart, updating: false }))
        }
      }

      const customAttributes: CustomAttribute[] = []

      const [, alternateParameter] = getQueryStringParameter('alt')
      if (alternateParameter === 'true') {
        createCookie('alternate_ashwagandha', 'true', 100) // TODO: Remove after testing done
      }

      const gaClientId = await initializeGaEnhancedEcommerce()
      if (gaClientId) {
        customAttributes.push({ key: 'google-clientID', value: gaClientId })
      }

      const [impactRadiusKey, impactRadiusValue] = getQueryStringParameter('irclickid')
      if (impactRadiusValue) {
        customAttributes.push({ key: impactRadiusKey, value: impactRadiusValue })
      }

      const [, everflowValue] = getQueryStringParameter('_ef_transaction_id')
      if (everflowValue) {
        customAttributes.push({ key: 'eftid', value: everflowValue })
      }

      const kickbooster = ['kbr_source', 'kbr_medium', 'kbr_content', 'kbr_campaign']
      const kickboosterAttributes = kickbooster
        .map(attr => getQueryStringParameter(attr))
        .filter((attr: any) => Boolean(attr[1]))

      if (kickboosterAttributes.length) {
        kickboosterAttributes.forEach((attr: any) => {
          customAttributes.push({ key: attr[0], value: attr[1] })
        })
      }

      const addTrackingAttributes = (cart: Cart) => {
        if (cart && cart.id && customAttributes.length) {
          return ShopifyClient.checkout.updateAttributes(cart.id, { customAttributes })
        }
        return cart
      }

      const createNewCart = () =>
        ShopifyClient.checkout
          .create()
          .then((cart: Cart) => addTrackingAttributes(cart))
          .catch((err: any) => {
            console.error('"createNewCart" error:', err)
            Sentry.captureException(err)
          })

      const fetchCart = (id: string) =>
        ShopifyClient.checkout
          .fetch(id)
          .then((cart: Cart) => addTrackingAttributes(cart))
          .catch((err: any) => {
            console.error('"fetchCart" error:', err)
            Sentry.captureException(err)
          })

      if (existingCartID) {
        try {
          const cart = await fetchCart(existingCartID)
          // Make sure this cart hasn’t already been purchased.
          if (cart && !cart.completedAt) {
            setCartId(existingCartID)
            return setStore((prevState: StoreInterface) => ({
              ...prevState,
              cart,
              updating: false,
            }))
          }
          if (isBrowser) deleteCookie(SHOPIFY_CART_ID)
        } catch (e) {
          if (isBrowser) deleteCookie(SHOPIFY_CART_ID)
          Sentry.captureException(e)
        }
      }
      const newCart: Cart | void = await createNewCart()
      if (newCart && newCart.id) {
        const newCartId = String(newCart.id)
        setCartId(newCartId)
        setCartInState(newCart)
      }
    } catch (err) {
      console.error('initializeCart', err)
      Sentry.captureException(err)
    }
  }

  // const checkItemExists = (checkout: any, item: any) => {
  //   if (checkout && checkout.lineItems) {
  //     return checkout.lineItems.find((node: any) => {
  //       const matchVariant = node.variant.id === item.variantId
  //       const matchSubscriptionInterval = item.frequency
  //         ? node.customAttributes.find(
  //             ({ key, value }: AttributeProps) => key === 'shipping_interval_frequency' && value === item.frequency.toString()
  //           ) && node.customAttributes.find(({ key, value }: AttributeProps) => key === 'shipping_interval_unit_type' && value === 'Days')
  //         : true
  //       return matchSubscriptionInterval && matchVariant
  //     })
  //   }
  //   return false
  // }

  const addVariantToCart = async (
    variantId: string,
    quantity: number,
    sellingPlan?: { [key: string]: string }
  ) => {
    try {
      if (!cartId) throw new Error('Missing cart ID')
      if (!variantId) throw new Error('Missing variant ID')
      if (!quantity) throw new Error('Missing product quantity')

      setStore(prevState => ({ ...prevState, updating: true }))

      const encodedVariantId = encodeVariantId(variantId)

      const attributes: CustomAttribute[] = []

      if (sellingPlan) {
        attributes.push({ key: 'selling_plan', value: String(sellingPlan.shopifyNumericId) })
        attributes.push({
          key: 'selling_plan_price_discount',
          value: String(sellingPlan.priceDiscount),
        })
      }

      const lineItem = {
        variantId: encodedVariantId,
        quantity,
        customAttributes: attributes,
      }

      return await ShopifyClient.checkout.addLineItems(cartId, [lineItem]).then((cart: Cart) => {
        setStore((prevState: StoreInterface) => ({ ...prevState, cart, updating: false }))
        const { shopifySku } = productMap.byVariantId[variantId]
        const [basicSku, packCount] = shopifySku.split('_')
        const count = packCount ? packCount[0] : 0
        const productType = count > 1 ? `multipack_${count}` : 'single'
        const lineItemDetail = productMap.byProduct[basicSku][productType]
        const variantPrice = sellingPlan?.price || lineItemDetail.pricing.oneTime.price
        trackAddToCartEvent(shopifySku, quantity, variantId, variantPrice)
        return cart
      })
    } catch (err) {
      console.error('addVariantToCart', err)
      Sentry.captureException(err)
    }
  }

  const removeVariantFromCart = async (lineItemID: string) => {
    try {
      if (!cartId) throw new Error('Missing cart ID')

      setStore((prevState: StoreInterface) => ({ ...prevState, updating: true }))
      return await ShopifyClient.checkout
        .removeLineItems(cartId, [lineItemID])
        .then((cart: Cart) => {
          setStore((prevState: StoreInterface) => ({ ...prevState, cart, updating: false }))
        })
    } catch (err) {
      console.error('removeVariantFromCart', err)
      Sentry.captureException(err)
    }
  }

  const updateCartLineItem = async (lineItemID: string, quantity: number) => {
    try {
      if (!cartId) throw new Error('Missing cart ID')

      setStore((prevState: StoreInterface) => ({ ...prevState, updating: true }))
      return cartId
        ? await ShopifyClient.checkout
            .updateLineItems(cartId, [{ id: lineItemID, quantity }])
            .then((cart: Cart) => {
              setStore((prevState: StoreInterface) => ({ ...prevState, cart, updating: false }))
            })
        : null
    } catch (err) {
      console.error('updateCartLineItem', err)
      Sentry.captureException(err)
    }
  }

  const swapVariant = async (lineItemID: string, newVariantId: string, quantity: number) => {
    try {
      if (!cartId) throw new Error('Missing cart ID')

      setStore((prevState: StoreInterface) => ({ ...prevState, updating: true }))
      await removeVariantFromCart(lineItemID)
      return await addVariantToCart(newVariantId, quantity)
    } catch (err) {
      console.error('swapVariant', err)
      Sentry.captureException(err)
    }
  }

  const clearCart = (cart: Cart) => {
    const allIds = cart.lineItems.map((item: any) => item.id)
    return ShopifyClient.checkout.removeLineItems(cartId, allIds).then((cart: Cart) => {
      setStore((prevState: StoreInterface) => ({ ...prevState, cart, updating: false }))
    })
  }

  // const convertToSubscription = async (
  //   lineItem: any,
  //   newFrequency: number,
  // ) => {
  //   try {
  //     setStore((prevState: any) => ({ ...prevState, updating: true }))

  //     const decodedVariantId = decodeVariantId(lineItem.variant.id)
  //     const newVariantId = variantSwapMap[decodedVariantId].twin

  //     let attributes: Array<AttributeProps> = []
  //     if (newFrequency) {
  //       attributes = [
  //         { key: 'shipping_interval_frequency', value: newFrequency.toString() },
  //         { key: 'shipping_interval_unit_type', value: 'Days' }
  //       ]
  //     }

  //     const updatedLineItem = [
  //       {
  //         id: lineItem.id,
  //         variantId: encodeVariantId(newVariantId),
  //         customAttributes: attributes
  //       }
  //     ]

  //     return await Client.checkout.updateLineItems(store.checkout.id, updatedLineItem).then((checkout: any) => {
  //       setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
  //     })
  //   } catch (err) {
  //     console.error('convertToSubscription', err)
  //     Sentry.captureException(err)
  //   }
  // }

  // const updateSubscriptionFrequency = async (lineItemId: string, newFrequency: number) => {
  //   try {
  //     setStore((prevState: any) => ({ ...prevState, updating: true }))

  //     const updatedLineItem = [
  //       {
  //         id: lineItemId,
  //         customAttributes: [
  //           { key: 'shipping_interval_frequency', value: newFrequency.toString() },
  //           { key: 'shipping_interval_unit_type', value: 'Days' }
  //         ]
  //       }
  //     ]

  //     return await Client.checkout.updateLineItems(store.checkout.id, updatedLineItem).then((checkout: any) => {
  //       setStore((prevState: any) => ({ ...prevState, checkout, updating: false }))
  //     })
  //   } catch (err) {
  //     console.error('updateSubscriptionFrequency', err)
  //     Sentry.captureException(err)
  //   }
  // }

  return {
    store,
    initializeCart,
    addVariantToCart,
    removeVariantFromCart,
    updateCartLineItem,
    swapVariant,
    clearCart,
  }
}

export default useCart
