/* eslint-disable no-undef */

import React from 'react'
import * as Sentry from '@sentry/react'
import { ThemeProvider } from 'styled-components'
import 'whatwg-fetch'

import StoreContextProvider from './src/provider/StoreContextProvider.tsx'

import theme from './src/styles/theme.tsx'
import './src/styles/typography.css'

export const wrapRootElement = props => {
  const { element } = props
  return (
    <StoreContextProvider>
      <ThemeProvider theme={theme}> {element} </ThemeProvider>
    </StoreContextProvider>
  )
}

export const onClientEntry = () => {
  Sentry.init({
    environment: process.env.NODE_ENV || 'development',
    // Netlify - https://docs.netlify.com/configure-builds/environment-variables/#build-metadata
    release: __SENTRY_RELEASE__,
    dsn: __SENTRY_DSN__,
    enabled: process.env.NODE_ENV === 'production',
    denyUrls: [/.*giveaways.*/i]
  })
  window.Sentry = Sentry
}
