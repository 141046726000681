import React from 'react'

interface ContextInterface {
  loading: boolean
  [key: string]: any
}

const defaultContext: ContextInterface = { loading: true }

const StoreContext = React.createContext(defaultContext)

export default StoreContext
