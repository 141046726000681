import { useState } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/react'

import decodeVariantId from '../utils/decodeVariantId'

interface SubscriptionDetailsInterface {
  shopifyNumericId?: string
  price?: number
  percentageDiscount?: number
  priceDiscount?: number
}

interface ProductDetailInterface {
  productKey?: string
  quantityKey?: string
  variantId?: string
  pricing?: {
    oneTime: { price: number }
    compareAt: { price: number }
    subscription: SubscriptionDetailsInterface
  }
}

interface MappedProductDetailInterface {
  jsonData: { [key: string]: any }
  [key: string]: ProductDetailInterface
}

interface VariantDetailInterface {
  basicSku: string
  shopifySku: string
  productId: string
}

interface JsonInterface {
  edges: {
    node: { [key: string]: any }
  }[]
}

interface ProductMapInterface {
  byProduct: { [key: string]: MappedProductDetailInterface }
  byVariantId: { [key: string]: VariantDetailInterface }
}

interface JsonMapInterface {
  [key: string]: { [key: string]: any }
}

const useProductDetail = (ShopifyClient?: any, data?: { allDataJson?: JsonInterface }) => {
  const [initializing, setInitializing] = useState(true)
  const [map, setMap] = useState<ProductMapInterface>({ byProduct: {}, byVariantId: {} })

  // Builds a map of the content/copy/images/data stored in the JSON files
  let productJsonMap: JsonMapInterface

  if (data && data.allDataJson) {
    productJsonMap = data.allDataJson.edges.reduce(
      (acc: JsonMapInterface, { node }: { node: { [key: string]: any } }) => {
        acc[node.sku] = node
        return acc
      },
      {}
    )
  }

  const fetchSmartrrSubscriptionDetail = async () => {
    try {
      const { data: smartrrData } = await axios.get(
        'https://api.smartrr.com/vendor/selling-plan-group',
        {
          headers: { 'x-smartrr-access-token': process.env.GATSBY_SMARTRR_API_KEY },
        }
      )

      return smartrrData
    } catch (e) {
      console.error('Smartrr API Error: ', e)
      return null
    }
  }

  const fetchShopifyProductDetail = async () => {
    try {
      const smartrrResponse = await fetchSmartrrSubscriptionDetail()

      const productsQuery = await ShopifyClient.graphQLClient.query((root: any) => {
        root.addConnection('products', { args: { first: 100 } }, (product: any) => {
          product.add('id')
          product.add('title')
          product.addConnection('variants', { args: { first: 5 } }, (variant: any) => {
            variant.add('id')
            variant.add('sku')
            variant.add('price')
            variant.add('compareAtPrice')
            variant.add('available')
          })
        })
      })

      const { data: shopifyData } = await ShopifyClient.graphQLClient.send(productsQuery)

      const productMap: ProductMapInterface = {
        byProduct: {},
        byVariantId: {},
      }

      if (shopifyData && shopifyData.products) {
        shopifyData.products.edges.forEach(({ node: product }: { node: any }) => {
          const variant = product.variants.edges[0].node

          // Bonus bottle SKUs should not be included in product map
          if (variant.sku.includes('_BONUS')) {
            // console.log(variant.sku, decodeVariantId(variant.id))
            return
          }

          const splitSku: string = variant.sku.split('_')
          const productKey: string = splitSku[0]
          const quantity: string = splitSku[1] ? splitSku[1].split('PK')[0] : '1'
          const quantityKey: string = quantity === '1' ? 'single' : `multipack_${quantity}`

          // productMap.byVariantId is used as a "key lookup" to re-use with productMap.byProduct
          const encodedVariantId = variant.id
          const decodedVariantId = decodeVariantId(encodedVariantId)
          const decodedProductId = decodeVariantId(product.id)

          const productBasics = {
            basicSku: productKey,
            shopifySku: variant.sku,
            productId: decodedProductId,
          }

          productMap.byVariantId[encodedVariantId] = productBasics
          productMap.byVariantId[decodedVariantId] = productBasics

          if (!productMap.byProduct[productKey]) {
            productMap.byProduct[productKey] = {
              jsonData: productJsonMap[productKey],
              single: {},
            }
          }

          const [sellingPlanGroup] = smartrrResponse.filter((group: any) => {
            return quantityKey === 'single' && group.productIds.includes(Number(decodedProductId))
          })

          const productData: ProductDetailInterface = {
            productKey,
            quantityKey,
            variantId: decodedVariantId,
            pricing: {
              oneTime: { price: Number(variant.price) },
              compareAt: { price: Number(variant.compareAtPrice) },
              subscription: {},
            },
          }

          // If the product has a selling plan, assign subscription details to productData
          if (sellingPlanGroup && quantityKey === 'single' && productData.pricing) {
            const sellingPlan = sellingPlanGroup.sellingPlans[0]
            const pricingPolicy = sellingPlan.pricingPolicies[0]
            const percentageDiscount = pricingPolicy.adjustmentValue.percentage / 100
            const priceDiscount = Number(variant.price) * percentageDiscount

            productData.pricing.subscription = {
              shopifyNumericId: sellingPlan.shopifyNumericId,
              price: Number(variant.price) - priceDiscount,
              percentageDiscount,
              priceDiscount,
            }
          }

          productMap.byProduct[productKey][quantityKey] = productData
        })
      }

      setMap(productMap)
      setInitializing(false)
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const lookupProducts = (productKeys: string[]) => {
    return productKeys.reduce(
      (acc: { [key: string]: MappedProductDetailInterface }, productKey: string) => {
        const productDetail = map.byProduct[productKey]
        if (productDetail) acc[productKey] = productDetail
        return acc
      },
      {}
    )
  }

  return {
    initializing,
    productMap: map,
    fetchShopifyProductDetail,
    lookupProducts,
  }
}

export default useProductDetail
